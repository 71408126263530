.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.SF-body {
    font-family: 'Space Mono', monospace;
}
.SF-header {
    font-family: 'Source Sans Pro', sans-serif;
}

.divider::after {
    display: inline-block;
    content: '';
    border-top: 0.1rem solid black;
    width: 8rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
}

.shade-parallax {
    background-image: linear-gradient(rgba(0, 0, 0, 2), rgba(0, 0, 0, 2));
    background-color: rgba(0, 0, 0, 1);
}

.learnit {
    background-image: url('/src/assets/built-to-learn.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.primary-background {
    background-color: #0a192f;
}

p,
h2,
h1,
h3,
h4 {
    color: #ccd6f6;
}
body {
    overscroll-behavior-y: none;
}

.parallax-img{
    /* background-image: url('/src/assets/financial-independence.png'); */
    height: 35vh;
    width: 35vw;
    background-size: 100% 100%;
    border: 2px black solid;
    height: 35vh;
    width: 35vw;
    background-size: 100% 100%;
    border: 2px black solid;
    margin-top: 2rem;  
}

.title{
    font-size: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
}

.subheader{
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
}

.me-description{
    color: white;
}




@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px){
  
    .parallax-img{
        height: 30vh;
        width: 90vw;
        background-size: 100% 100%;
        border: 2px black solid;
        background-size: 100% 100%;
        border: 2px black solid;
        margin-top: 1rem; 
    }

    #timeline-title{
        font-size: 0.8rem;
    }

    #timeline-desc{
        font-size: 0.7rem;
    }
    
    .title{
        font-size: 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: white;
    }

    .subheader{
        font-size: 1.25rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .me-description{
        color: white;
        /* text-align: justify; */
    }

    .flip-direction{
        display: flex;
        flex-direction: column-reverse;
    }


    div[style] >{
        height: '50vh' !important;
        width: '50vw' !important;
        background-size: '100% 100%';
        border: '2px black solid';
    }

    .footer-title{
        font-size: 1.5rem;

    }

    .footer-description{
        font-size: 0.8rem;

    }

    
}

